.wrapper {
  font-family: 'Inter';
  z-index: 2;
  position: relative;
  height: 90px;
  width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1140px) {
    width: 960px;
  }

  @media (max-width: 1000px) {
    width: 720px;
  }

  @media (max-width: 740px) {
    width: 400px;
  }

  @media (max-width: 430px) {
    width: 330px;
  }
}
.nav {
  font-style: normal;
  text-transform: capitalize;
  display: flex;
  gap: 40px;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #e2e2e2;

  @media (max-width: 1200px) {
    gap: 24px;
  }

  @media (max-width: 740px) {
    display: none;
  }
}
.nav > div {
  cursor: not-allowed;
  opacity: 0.5;
}

.logo_wrapper {
  cursor: pointer;
}
.main_button {
  font-family: 'Inter';
  cursor: pointer;
  pointer-events: none;
  width: 206px;
  height: 60px;
  background-color: #00000000;
  color: #00000000;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  border-radius: 12px;
  border: none;

  @media (max-width: 430px) {
    width: 168px;
    height: 48px;
  }
}
.button {
  font-family: 'Inter';
  cursor: pointer;
  // pointer-events: none;
  width: 130px;
  height: 36px;
  background: #473c79;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border-radius: 38px;
  border-width: 1px;
  border: none;
  position: relative;
}
.button::before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 38px;
  top: -0.1em;
  bottom: -0.1em;
  right: -0.1em;
  left: -0.1em;
  z-index: -1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(71, 60, 121, 0.44) 90%
  );
}
