.bg-images {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
}
.block_wrapper {
  width: 1140px;
  margin: 0 auto;
  font-weight: 500;
  gap: 20px;
  font-size: 18px;
  line-height: 16px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;

  @media (max-width: 1140px) {
    width: 960px;
    padding-bottom: 60px;
  }

  @media (max-width: 1000px) {
    width: 720px;
    padding-bottom: 60px;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 720px;
    padding-bottom: 60px;
  }

  @media (max-width: 430px) {
    gap: 10px;
    width: 400px;
    padding-bottom: 40px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.title {
  max-width: 1036px;
  white-space: break-spaces;
  color: #ffffff;
  font-weight: 600;
  font-size: 56px;
  margin-bottom: 0;
  margin-top: 145px;
  line-height: 65px;
  text-align: center;
  // background: linear-gradient(180deg, #ffffff 0%, #dac7ff 100%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // background-clip: text;

  span {
    background: linear-gradient(
      90deg,
      rgba(148, 69, 249, 1),
      rgba(200, 130, 255, 1),
      rgba(132, 147, 255, 1)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  @media (max-width: 1024px) {
    max-width: 740px;
  }
  @media (max-width: 820px) {
    font-weight: 600;
    font-size: 42px;
    line-height: 64px;
    margin-top: 110px;
  }
  @media (max-width: 730px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 429px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    width: 300px;
  }
}
.sub_text {
  font-style: normal;
  text-align: center;
  white-space: break-spaces;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  max-width: 700px;
  color: #d2d0dd;
  margin: 0px;
  margin-bottom: 20px;
  letter-spacing: -0.2px;

  @media (max-width: 1024px) {
    max-width: 540px;
  }

  @media (max-width: 768px) {
    font-weight: 400;
    font-size: 16px;
  }
  @media (max-width: 730px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 430px) {
    padding-bottom: 30px;
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 150%;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.mainBlock_button {
  position: relative;
  font-family: 'Inter';
  cursor: pointer;
  width: 160px;
  height: 48px;
  background: #876afa;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  border-radius: 38px;
  border: none;

  @media (max-width: 430px) {
    width: 168px;
    height: 48px;
  }
}
.mainBlock_button::before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 38px;
  top: -0.1em;
  bottom: -0.1em;
  right: -0.1em;
  left: -0.1em;
  z-index: -1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(135, 106, 250, 0.5) 100%
  );
}

.main_modal_wrapper {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.main-block {
  margin-top: 80px;
  width: 100%;
  margin-bottom: 255px;

  @media (max-width: 768px) {
    margin-bottom: 265px;
  }
  @media (max-width: 430px) {
    margin-top: 100px;
    margin-bottom: 295px;
  }
}

.main-text {
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    //  align-items: center;
  }
  @media (max-width: 730px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 430px) {
    align-items: center;
  }
  &__title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 42px;
    line-height: 64px;
    color: #ffffff;
    @media (max-width: 768px) {
      font-weight: 700;
      font-size: 32px;
      line-height: 64px;
      // text-align: center;
    }
    @media (max-width: 430px) {
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
    }
  }
  &__text {
    width: 630px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.2px;
    color: #d2d0dd;
    @media (max-width: 1024px) {
      font-weight: 400;
      font-size: 18px;
      width: 445px;
    }
    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 16px;
      width: 375px;
      // text-align: center;
    }
    @media (max-width: 430px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      width: 260px;
      text-align: center;
    }
  }
}
.social-link-block {
  display: flex;
  align-items: center;
  gap: 12px;
}
