.main-coming-soon-wrapper {
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.coming-soon-wrapper {
  background-image: url('../../img/modal-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 20px;
  height: 260px;
  width: 420px;
  @media (max-width: 429px) {
    width: 340px;
  }

  &__content {
    position: relative;
    padding: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  &__title {
    margin-top: 12px;
    margin-bottom: 60px;
    font-weight: 700;
    font-size: 36px;
    color: #ffffff;
    line-height: 64px;
    background: linear-gradient(180deg, #ffffff 0%, #dac7ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  &__button {
    font-family: 'Inter';
    cursor: pointer;
    width: 160px;
    height: 48px;
    background: #876afa;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    border-radius: 38px;
    border-width: 1px;
    border: none;
    position: relative;
  }
  &__button::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 38px;
    top: -0.1em;
    bottom: -0.1em;
    right: -0.1em;
    left: -0.1em;
    z-index: -1;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.5) 10%,
      rgba(135, 106, 250, 0.5) 100%
    );
  }
}
